<template>
  <div class="v-moods-info">
    <explore-info-title bg="moods">
      <!-- {{ moodsCategory[route.params.category].ch }} {{ moodsCategory[route.params.category].en }} -->
      {{ route.params.category }}
    </explore-info-title>

    <div class="v-moods-info__content">
      <div class="normal">
        <div class="normal__content g-content">
          <normal-box-rwd-transform :data="normalPlaylistsMockData">
            <template v-slot:header>
              <explore-title title="歌單">
                <template v-slot:extra>
                  <SeeMore text="See all in search" :to="toSearchPlaylists" :show="['init']" />
                </template>
                <template v-slot:right>
                  <SeeMore text="See all in search" :to="toSearchPlaylists" :show="['tablet']" />
                </template>
              </explore-title>
            </template>
            <template v-slot="playlistsData">
              <playlists-box-normal color="white" :data="playlistsData.data" />
            </template>
          </normal-box-rwd-transform>
        </div>
      </div>

      <div class="popular popular--deco">
        <div
          :class="{
            'g-content': addGContent,
          }"
        >
          <popular-box-rwd-transform :data="popularAlbumMockData">
            <template v-slot:header>
              <explore-title title="專輯">
                <template v-slot:extra>
                  <SeeMore text="See all in search" :to="toSearchAlbums" :show="['init']" />
                </template>
                <template v-slot:right>
                  <SeeMore text="See all in search" :to="toSearchAlbums" :show="['tablet']" />
                </template>
              </explore-title>
            </template>
            <template v-slot="albumData">
              <albums-box-popular :key="i" :data="albumData.data" />
            </template>
          </popular-box-rwd-transform>
        </div>
      </div>

      <div class="g-content">
        <explore-title title="曲目">
          <template v-slot:extra>
            <SeeMore text="See all in search" :to="toSearchTracks" :show="['init']" />
          </template>
          <template v-slot:right>
            <SeeMore text="See all in search" :to="toSearchTracks" :show="['tablet']" />
          </template>
        </explore-title>
        <div class="hot-music-list-wrap">
          <hot-music-list />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { moodsCategory } from '@/config/categoryConfig';
import ExploreTitle from '@/components/Explore/ExploreTitle.vue';
import AlbumsBoxPopular from '@/components/Explore/AlbumsBoxPopular.vue';
// import AlbumsBoxNormal from '@/components/Explore/AlbumsBoxNormal.vue';
// import AlbumsBoxRelative from '@/components/Explore/AlbumsBoxRelative.vue';
// import PlaylistsBoxPopular from '@/components/Explore/PlaylistsBoxPopular.vue';
import PlaylistsBoxNormal from '@/components/Explore/PlaylistsBoxNormal.vue';
// import PlaylistsBoxRelative from '@/components/Explore/PlaylistsBoxRelative.vue';
import SeeMore from '@/components/Global/SeeMore.vue';
import { useSiteStore } from '@/store/site';
import ExploreInfoTitle from '@/components/Explore/ExploreInfoTitle.vue';
import { popularAlbumMockData, normalAlbumMockData, relativeAlbumMockData } from '@/config/mockData/albumsBox';
import {
  popularPlaylistsMockData,
  normalPlaylistsMockData,
  relativePlaylistsMockData,
} from '@/config/mockData/playlistsBox';
import PopularBoxRwdTransform from '@/components/Global/PopularBoxRwdTransform.vue';
import NormalBoxRwdTransform from '@/components/Global/NormalBoxRwdTransform.vue';
// import RelativeBoxRwdTransform from '@/components/Global/RelativeBoxRwdTransform.vue';
import useAddGContent from '@/hooks/useAddGContent';
import HotMusicList from '@/components/Explore/HotMusicList.vue';

export default defineComponent({
  name: 'MoodsInfo',
  components: {
    ExploreTitle,
    AlbumsBoxPopular,
    // AlbumsBoxNormal,
    // AlbumsBoxRelative,
    // PlaylistsBoxPopular,
    PlaylistsBoxNormal,
    // PlaylistsBoxRelative,
    SeeMore,
    ExploreInfoTitle,
    PopularBoxRwdTransform,
    NormalBoxRwdTransform,
    // RelativeBoxRwdTransform,
    HotMusicList,
  },
  setup() {
    const route = useRoute();
    const siteStore = useSiteStore();
    const addGContent = useAddGContent();

    const toSearchPlaylists = {
      name: 'search',
      query: {
        tab: 'playlists',
      },
    };

    const toSearchTracks = {
      name: 'search',
      query: {
        tab: 'tracks',
      },
    };

    const toSearchAlbums = {
      name: 'search',
      query: {
        tab: 'albums',
      },
    };

    // 離開此頁時，清除背景
    onBeforeRouteLeave(() => {
      siteStore.setBackgroundImage(false);
    });

    return {
      route,
      moodsCategory,
      toSearchPlaylists,
      toSearchTracks,
      toSearchAlbums,
      addGContent,
      popularPlaylistsMockData,
      normalPlaylistsMockData,
      popularAlbumMockData,
      normalAlbumMockData,
      relativePlaylistsMockData,
      relativeAlbumMockData,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/albumsAndPlaylists.scss';

.v-moods-info {
  &__content {
    padding-bottom: 130px;
  }
}

@media screen and (min-width: $tablet) {
  .v-moods-info {
    &__header {
      height: 270px;
      padding-top: 80px;
      background-image: url('~@/assets/explore/moods_img.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center right;
      position: relative;
    }
  }

  .breadcrumb-wrap {
    height: 60px;
    @include max-width(1700);
    @include flex();
  }

  .explore-title {
    margin-top: 26px;
    @include position(center);
    text-align: center;

    &__sub {
      @include font-style($c-white, 16, medium, 0.8px);
    }

    &__main {
      margin-top: 12px;
      @include font-style($c-white, 45, bold, 2.25px, 60px);
    }
  }

  .info-section {
    & + & {
      margin-top: 100px;
    }

    &__content {
      @include max-width(1366);
    }
  }
}
</style>
